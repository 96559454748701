import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Select } from "clutch/src/Select/Select";
import { mobile } from "clutch/src/Style/style.mjs";
import { TextInput } from "clutch/src/TextInput/TextInput";

import { readState } from "@/__main__/app-state.mjs";
import {
  appURLs,
  FALLBACK_IMAGE_URL,
  MIN_STRING_DISTANCE,
} from "@/app/constants.mjs";
import type { Rarity } from "@/game-fortnite/components/ItemBox.jsx";
import { ItemBox, ItemContainer } from "@/game-fortnite/components/ItemBox.jsx";
import WeaponsStatsTable from "@/game-fortnite/components/WeaponsStatsTable";
import Rarities, {
  RARITY_SYMBOLS,
} from "@/game-fortnite/constants/rarities.mjs";
import { WEAPON_CATEGORIES } from "@/game-fortnite/constants/weaponCategories.mjs";
import { GAME_SYMBOL_FORTNITE } from "@/game-fortnite/definition-symbol.mjs";
import type { Item } from "@/game-fortnite/models/items.mjs";
import getWeaponKey from "@/game-fortnite/utils/get-weapon-key.mjs";
import {
  getCritDamage,
  getDamage,
  getDamagePerSecond,
  getEnvDamage,
} from "@/game-fortnite/utils/weapon-stats.mjs";
import DataTable from "@/shared/DataTable.jsx";
import { WikiCard, WikiLayout, WikiSidebar } from "@/shared/Wiki.jsx";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useQuery, useRoute } from "@/util/router-hooks.mjs";
import stringCompare from "@/util/string-compare.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const headerClass = () => css`
  .header-image--img {
    transform: none !important;
  }

  .header-image--inner {
    &::after {
      box-shadow: none !important;
      border: none !important;
    }
  }
`;

const sidebarCss = () => css`
  section:first-child {
    width: initial;
    margin: var(--sp-6);
    background: hsla(222, 16%, 11%, 1);

    img {
      width: var(--sp-30);
      height: var(--sp-30);
      margin: var(--sp-2_5) auto;
    }
  }
`;

const searchContainer = () => css`
  display: flex;
  flex-direction: row;
  gap: var(--sp-2);

  ${mobile} {
    flex-direction: column;

    .selectButton {
      width: 100%;
    }
  }
`;

const rarityOptions = Object.getOwnPropertySymbols(Rarities).map((r) => {
  return {
    value: Rarities[r].key,
    text: Rarities[r].t,
    icon:
      Rarities[r].key !== "all" ? (
        <span
          style={{
            width: "var(--sp-2)",
            height: "var(--sp-2)",
            borderRadius: "var(--sp-0_5)",
            background: `hsla(${Rarities[r].color}, 1)`,
            alignSelf: "center",
          }}
        />
      ) : null,
  };
});

function WeaponInfoSidebar({ weapons }: { weapons: Item[] }) {
  const { t } = useTranslation();
  const weapon = weapons[0];
  const categorySymbol = Object.getOwnPropertySymbols(WEAPON_CATEGORIES).find(
    (symbol) => {
      return WEAPON_CATEGORIES[symbol].key === weapon?.category;
    },
  );
  const weaponCategory = WEAPON_CATEGORIES[categorySymbol];
  const multiplier = "x";
  const rarities = Array.from(new Set(weapons.map((v) => v.rarity)))
    .map(
      (r) =>
        Rarities[
          Object.getOwnPropertySymbols(Rarities).find(
            (s) => Rarities[s].key === r.toLowerCase(),
          )
        ],
    )
    .filter((r) => r)
    .sort((a, b) => a.rank - b.rank);
  return (
    <WikiSidebar
      imgSrc={`${appURLs.CDN}/fortnite/images/${weapon.icon}.webp`}
      itemName={
        [
          "fortnite:database.weaponName",
          "{{weaponName}}",
          { weaponName: weapon.displayName },
        ] as Translation
      }
      className={sidebarCss()}
    >
      <WikiSidebar.Section>
        <dl>
          <dt>{t("fortnite:rarity", "Rarity")}</dt>
          <dd className="flex wrap justify-end">
            {rarities.map((r, index) => (
              <span key={r.key} style={{ color: `hsla(${r.color}, 1)` }}>
                {t(...r.t)}
                {index !== rarities.length - 1 ? (
                  <span className="shade0">&nbsp;/&nbsp;</span>
                ) : null}
              </span>
            ))}
          </dd>
          <dt>{t("fortnite:weaponType", "Type")}</dt>
          <dd>
            {t(
              ...(weaponCategory
                ? weaponCategory.t
                : ["fortnite:weaponCategories.other", "Other"]),
            )}
          </dd>
          {/*<dt>{t("fortnite:ammo", "Ammo")}</dt>*/}
          {/*<dd>{weapon.ammo}</dd>*/}
        </dl>
      </WikiSidebar.Section>
      <WikiSidebar.Section>
        <WikiSidebar.Header>
          {t("fortnite:statistics", "Statistics")}
        </WikiSidebar.Header>
        <dl>
          <dt>{t("fortnite:stats.fireRate", "Fire Rate")}</dt>
          <dd>{weapon.stats.firingRate}</dd>
          <dt>{t("fortnite:stats.magazineSize", "Magazine Size")}</dt>
          <dd>{weapon.stats.clipSize}</dd>
          <dt>
            {t(
              "fortnite:stats.critDamageMultiplier",
              "Crit. Damage Multiplier",
            )}
          </dt>
          <dd>
            {multiplier}
            {weapon.stats.criticalDamageMultiplier}
          </dd>
        </dl>
      </WikiSidebar.Section>
    </WikiSidebar>
  );
}

function DatabaseWeapon() {
  const { t } = useTranslation();
  const {
    parameters: [weaponId],
  } = useRoute();
  const {
    fortnite: { items },
  } = useSnapshot(readState);

  const weaponWithVariants: Item[] = useMemo(() => {
    if (!items) return [];
    const existingRarities = [];
    return Object.values(items).filter((item: Item) => {
      const isWeapon =
        item.type === "Item" &&
        getWeaponKey(item.displayName) === weaponId &&
        !existingRarities.includes(item.rarity);
      if (isWeapon) existingRarities.push(item.rarity);
      return isWeapon;
    });
  }, [weaponId, items]);
  const weapon = weaponWithVariants?.[0];

  const variantWeapons = useMemo(() => {
    if (weaponWithVariants.length === 0) return;
    return weaponWithVariants.map((v) => {
      return [
        {
          display: (
            <ItemContainer>
              <ItemBox
                id={weaponId}
                name={v.displayName}
                rarity={v.rarity.toUpperCase() as Rarity}
                src={`${appURLs.CDN}/fortnite/images/${v.icon}.webp`}
              />
              <p className="type-callout--bold shade0 name">{v.rarity}</p>
            </ItemContainer>
          ),
          value: v.rarity,
        },
        {
          display: getLocaleString(getDamagePerSecond(v.stats)),
          value: getDamagePerSecond(v.stats),
        },
        {
          display: getLocaleString(getDamage(v.stats)),
          value: getDamage(v.stats),
        },
        {
          display: getLocaleString(getEnvDamage(v.stats)),
          value: getEnvDamage(v.stats),
        },
        {
          display: getLocaleString(getCritDamage(v.stats)),
          value: getCritDamage(v.stats),
        },
        {
          display: getLocaleString(v.stats.reloadTime),
          value: v.stats.reloadTime,
        },
        {
          display: getLocaleString(v.stats.firingRate),
          value: v.stats.firingRate,
        },
        {
          display: getLocaleString(v.stats.clipSize),
          value: v.stats.clipSize,
        },
      ];
    });
  }, [weaponWithVariants, weaponId]);

  const categorySymbol = Object.getOwnPropertySymbols(WEAPON_CATEGORIES).find(
    (symbol) => {
      return WEAPON_CATEGORIES[symbol].key === weapon?.category;
    },
  );
  const weaponCategory = WEAPON_CATEGORIES[categorySymbol];

  const [nameFilter, setNameFilter] = useQuery<string>("name", "");
  const [rarityFilter, setRarityFilter] = useQuery<string>(
    "rarity",
    Rarities[RARITY_SYMBOLS.fortniteAllRarities].key,
  );
  const raritySymbol = Object.getOwnPropertySymbols(Rarities).find(
    (r) => Rarities[r].key === rarityFilter,
  );
  const categoryWeapons = useMemo(() => {
    if (!items || !weaponCategory) return [];
    return Object.values(items)
      .filter((w) => {
        return (
          w.type === "Item" &&
          w.category === weaponCategory.key &&
          (nameFilter
            ? stringCompare(nameFilter, w.displayName) > MIN_STRING_DISTANCE
            : true) &&
          Object.keys(w.stats || {}).length !== 0
        );
      })
      .map(({ id }) => id);
  }, [nameFilter, weaponCategory, items]);

  if (!items) {
    return (
      <WikiLayout
        title={["fortnite:weapon", "Weapon"]}
        imgSrc={FALLBACK_IMAGE_URL}
        gameSymbol={GAME_SYMBOL_FORTNITE}
        containerClassName={headerClass()}
      >
        <div className="sidebar">
          <WikiCard loading style={{ height: "1500px" }} />
        </div>
        <div className="main">
          <WikiCard loading style={{ height: "1500px" }} />
          <WikiCard loading style={{ height: "128px" }} />
        </div>
      </WikiLayout>
    );
  }

  if (!weapon) return null;

  return (
    <WikiLayout
      gameSymbol={GAME_SYMBOL_FORTNITE}
      title={
        [
          "fortnite:database.weaponName",
          "{{weaponName}}",
          { weaponName: weapon.displayName },
        ] as Translation
      }
      imgSrc={`${appURLs.CDN}/fortnite/images/${weapon.icon}.webp`}
      containerClassName={headerClass()}
    >
      <div className="sidebar">
        <WeaponInfoSidebar weapons={weaponWithVariants} />
      </div>
      <div className="main">
        <DataTable
          className="wiki-table"
          indexCol
          indexColTitle={"#"}
          sortCol={1}
          cols={[
            {
              display: t("fortnite:rarity", "Rarity"),
              primary: true,
              align: "left",
            },
            { display: t("fortnite:stats.dps", "DPS"), isStat: true },
            { display: t("fortnite:stats.damage", "Damage"), isStat: true },
            {
              display: t("fortnite:stats.structDamage", "Struct. Damage"),
              isStat: true,
            },
            {
              display: t("fortnite:stats.critDamage", "Crit. Damage"),
              isStat: true,
            },
            {
              display: t("fortnite.stats.reloadTime", "Reload Time"),
              isStat: true,
            },
            {
              display: t("fortnite.stats.rateOfFire", "Rate Of Fire"),
              isStat: true,
            },
            {
              display: t("fortnite.stats.magSize", "Mag. Size"),
              isStat: true,
            },
          ]}
          rows={variantWeapons}
        />
        <div className={searchContainer()}>
          <TextInput
            placeholder={t("common:search", "Search")}
            onChange={(e) => setNameFilter(e.target.value)}
            value={nameFilter}
          />
          <Select
            options={rarityOptions}
            selected={rarityFilter || ""}
            onChange={(v) => setRarityFilter(v || undefined)}
          />
        </div>
        <WeaponsStatsTable
          weaponIds={categoryWeapons}
          rarity={raritySymbol}
          className="wiki-table"
        />
      </div>
    </WikiLayout>
  );
}

export default DatabaseWeapon;

export function meta([weaponKey]) {
  const {
    fortnite: { items },
  } = readState;
  const weapon = Object.values(items || {}).find((item) => {
    return item.type === "Item" && getWeaponKey(item.displayName) === weaponKey;
  });
  return {
    title: weapon
      ? ([
          "fortnite:meta.database.weapon.name",
          "{{weaponName}} - Fortnite weapon",
          { weaponName: weapon.displayName },
        ] as Translation)
      : ["fortnite:meta.database.weapon.name.default", "Weapon"],
    description: weapon
      ? [
          `fortnite:meta.database.weapon.description.${weaponKey}`,
          weapon.description,
        ]
      : [
          "fortnite:meta.database.weapon.description.default",
          "Fortnite weapon description",
        ],
    subtitle: true,
  };
}
